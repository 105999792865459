.App {
  text-align: center;
  /* customized line below */
  line-height: 1.5; /* originally set to 2 */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* customized sections below */
table {
  border-collapse: separate;
  border-spacing: 20px 0;
}

/*
.popup-content {
  margin: auto;
  background: rgb(255, 255, 255);
  width: 75%;
  padding: 5px;
}
.popup-arrow {
  color: rgb(255, 255, 255);
}
[role='tooltip'].popup-content {
  width: 80%;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-color: #282c34;
  min-height: 50vh;
  font-size: calc(10px + 2vmin);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup='tooltip'].popup-overlay {
  background: transparent;
}*/


/* Styles for React Table
.ReactTable .rt-table {
  padding-bottom: 20px;
  -ms-overflow-style: scrollbar;
}

.ReactTable .-pagination .-previous:hover,
.ReactTable .-pagination .-next:hover {
  background-color: #3f51b5;
  border-radius: 3px;
  -moz-focus-inner {
    border: 0;
  }
}

.ReactTable .-loading > div {
  top: 50%;
  left: 50%;
  color: rgba(63, 81, 181, 1);
}

.BoldText {
  font-weight: bold;
}

.ColoredText {
  color : #3f51b5;
}

.LeftAlignedText {
  text-align: left;
}
*/

